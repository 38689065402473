import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import {
  WhiteLabelCreateUser,
  WhiteLabelCreateUserForLegalCompanyCreation,
  WhiteLabelEditUser,
  WhiteLabelEditUserForLegalCompanyCreation,
  WhiteLabelSearchUser,
} from '@dougs/white-label/dto';
import { WhiteLabelUserHttpService } from '../http/white-label-user.http';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelUserStateService extends StateService<User[]> {
  private readonly ACCOUNTANT_ROLE = 'accountant';
  private readonly ADMIN_ROLE = 'admin';

  users$: Observable<User[]> = this.select<User[]>((state) => state);

  constructor(
    private readonly whiteLabelUserHttpService: WhiteLabelUserHttpService,
    private readonly userStateService: UserStateService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async createUser(user: WhiteLabelCreateUser): Promise<User | null> {
    try {
      const createdUser: User = await lastValueFrom(this.whiteLabelUserHttpService.createUser(user));
      this.setFullState([createdUser, ...(this.state ?? [])]);
      return createdUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async createUserForLegalCompanyCreation(user: WhiteLabelCreateUserForLegalCompanyCreation): Promise<User | null> {
    try {
      const createdUser: User = await lastValueFrom(
        this.whiteLabelUserHttpService.createUserForLegalCompanyCreation(user),
      );
      this.setFullState([createdUser, ...(this.state ?? [])]);
      return createdUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateUser(userToEdit: WhiteLabelEditUser): Promise<User | null> {
    try {
      const editedUser: User = await lastValueFrom(this.whiteLabelUserHttpService.editUser(userToEdit));
      this.setFullState(this.state.map((user) => (user.id === editedUser.id ? editedUser : user)));
      return editedUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateUserForLegalCompanyCreation(userToEdit: WhiteLabelEditUserForLegalCompanyCreation): Promise<User | null> {
    try {
      const editedUser: User = await lastValueFrom(
        this.whiteLabelUserHttpService.editUserForLegalCompanyCreation(userToEdit),
      );
      this.setFullState(this.state.map((user) => (user.id === editedUser.id ? editedUser : user)));
      return editedUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  searchUsers(query: WhiteLabelSearchUser): Observable<User[]> {
    return this.whiteLabelUserHttpService
      .searchUser(query, this.userStateService.loggedInUser?.isAdmin ? this.ADMIN_ROLE : this.ACCOUNTANT_ROLE)
      .pipe(tap((foundUsers) => this.setFullState(foundUsers)));
  }

  async loadMoreUsers(query: WhiteLabelSearchUser, offset: number): Promise<void> {
    try {
      const moreUsers: User[] = await lastValueFrom(
        this.whiteLabelUserHttpService.searchUser(
          query,
          this.userStateService.loggedInUser?.isAdmin ? this.ADMIN_ROLE : this.ACCOUNTANT_ROLE,
          offset,
        ),
      );
      this.setFullState([...this.state, ...moreUsers]);
    } catch (e) {
      this.logger.error(e);
    }
  }
}

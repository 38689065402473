import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingFirm, AccountingFirmOffice } from '@dougs/white-label/dto';
import { AccountingFirmHttpService } from '../http/accounting-firm.http';

interface SettingsAdminState {
  accountingFirms: AccountingFirm[];
  accountingFirmOffices: AccountingFirmOffice[];
}

@Injectable({
  providedIn: 'root',
})
export class AccountingFirmStateService extends StateService<SettingsAdminState> {
  readonly accountingFirms$: Observable<AccountingFirm[]> = this.select((state) => state.accountingFirms);
  readonly accountingFirmOffices$: Observable<AccountingFirmOffice[]> = this.select(
    (state) => state.accountingFirmOffices,
  );

  constructor(
    private readonly logger: LoggerService,
    private readonly accountingFirmHttpService: AccountingFirmHttpService,
  ) {
    super();
  }

  isInternalAccountingFirm(accountingFirmId: number): boolean {
    return [1, 2, 3].includes(accountingFirmId);
  }

  async refreshAccountingFirms(): Promise<void> {
    try {
      this.setState({
        accountingFirms: await lastValueFrom(this.accountingFirmHttpService.getAccountingFirms()),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshAccountingFirmOffices(accountingFirmId: number): Promise<void> {
    try {
      this.setState({
        accountingFirmOffices: await lastValueFrom(
          this.accountingFirmHttpService.getAccountingFirmOffices(accountingFirmId),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}

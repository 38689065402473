import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AccountingFirm, AccountingFirmOffice } from '@dougs/white-label/dto';

@Injectable({
  providedIn: 'root',
})
export class AccountingFirmHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccountingFirms(): Observable<AccountingFirm[]> {
    return this.http.get<AccountingFirm[]>(`${AppConfig.settings.legacyApiServerUrl}/accounting-firms`);
  }

  getAccountingFirmOffices(accountingFirmId: number): Observable<AccountingFirmOffice[]> {
    return this.http.get<AccountingFirmOffice[]>(
      `${AppConfig.settings.legacyApiServerUrl}/accounting-firms/${accountingFirmId}/offices`,
    );
  }
}

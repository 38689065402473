import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '../states';

@Injectable({
  providedIn: 'root',
})
export class CanAccessToWhiteLabelGuard {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly routingService: RoutingService,
  ) {}
  canActivate(): boolean | UrlTree {
    const loggedInUser: User = this.userStateService.loggedInUser;

    if (
      loggedInUser.isAccountantOrAdmin &&
      (!this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId) ||
        loggedInUser.flags.includes('canSeeNewCompaniesModule'))
    ) {
      return true;
    }

    return this.routingService.createUrl([URL.ACCOUNTING]);
  }
}
